<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="术前教育内容管理"/>
        <div class="search" style="margin-top: 20px;text-align: center">
            <el-input v-model="keywords" size="small" style="width: 20%"></el-input>
            <el-button type="primary" plain size="small" style="margin-left:10px ">检索</el-button>
        </div>
        <div>
            <el-table stripe :data="table.data" style="width: 100%;margin-top:10px" v-loading="loading" border>
                <el-table-column type="index" width="50" align="center"/>
                <el-table-column align="center"
                                 v-for="(v, idx) in table.columns"
                                 :prop="v.field"
                                 :label="v.title"
                                 :width="v.width"
                                 :key="idx">
                </el-table-column>
                <el-table-column width="280" align="center">
                    <template slot="header" slot-scope="scope">
                        <el-input v-model="keywords" size="mini" @change="handleSearch(scope)" placeholder="输入关键字搜索"/>
                    </template>
                    <template slot-scope="scope">
                        <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                        <el-button size="mini" @click="handlePassword(scope.$index, scope.row)">重置密码</el-button>
                        <el-popover style="margin-left: 10px" :ref="`popover-${scope.$index}`" placement="top"
                                    width="180">
                            <p>确定删除[{{scope.row.username}}]的记录吗？</p>
                            <div>
                                <el-button type="danger" size="mini" @click="handleDelete(scope.$index, scope.row)">确定
                                </el-button>
                                <el-button size="mini" type="default"
                                           @click="$refs[`popover-${scope.$index}`].doClose()">取消
                                </el-button>
                            </div>
                            <el-button size="mini" type="danger" slot="reference">删除</el-button>
                        </el-popover>
                    </template>
                </el-table-column>
            </el-table>
            <div style="text-align: center;margin: 20px" v-if="table.total > table.pageSize">
                <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50]"
                               :total="table.total" :page-size="table.pageSize" @current-change="handleCurrentChange"
                               @size-change="handleSizeChange"/>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                table: {
                    columns: [
                        {title: '患者来源', field: 'username', width: '200'},
                        {title: '预诊类型', field: 'role', width: '200'},
                        {title: '初步诊断', field: 'realname', width: '200'},
                        {title: '患者信息', field: 'patient', width: ''},
                        {title: '检查情况', field: 'info', width: ''},
                    ],
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 10
                },
            }
        }
    }
</script>
